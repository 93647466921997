import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { ReadNormalBoardItem } from "../types/ReadNormalBoardItem";

dayjs.extend(utc);
dayjs.extend(timezone);

const DetailView = ({ data }: { data: ReadNormalBoardItem }) => {
  if (!data) {
    return <div>해당 텐트를 찾을 수 없습니다.</div>;
  }

  const formattedDate = dayjs(data.UpdatedAt)
    .tz("Asia/Seoul")
    .format("YYYY년 M월 D일 HH:mm:ss");

  return (
    <div className="flex flex-col sm:p-10 shadow-lg rounded-lg border-4 border-blue-200 w-[95%] sm:w-[85%]">
      <p className="text-black text-2xl font-bold p-4 border-b-4 border-blue-300 w-full">
        {data.Title}
      </p>
      <div className="flex flex-col items-end text-right pt-2 pr-2">
        <p>{formattedDate}</p>
{/*         <p>조회 {data.ViewCount}</p> */}
      </div>
      <div className="flex flex-col w-full sm:w-[80%] mx-auto">
        {data.ImgList.map((imgPath) => {
          const imgUrl = `${process.env.REACT_APP_IMAGE_BASE_URL}/${imgPath}`;
          return (
            <img
              className="max-w-full h-auto justify-center mx-auto mt-10"
              key={imgPath}
              src={imgUrl}
              alt={data.Title}
            />
          );
        })}
      </div>
    </div>
  );
};

export default DetailView;
