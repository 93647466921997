import React, { useEffect, useState } from "react";
import "../globals.css";
import SideBar from "../components/SideBar";
import PhotoSlider from "../components/home/PhotoSlider";
import Info from "../components/home/Info";
import ScrollTopButton from "../components/ScrollTopButton";
import Popup from "../components/home/Popup";

const Home: React.FC = () => {
  // const [showPopup, setShowPopup] = useState(false);

  // // 1일 동안 보지 않기 상태 확인
  // useEffect(() => {
  //   const popupTimestamp = localStorage.getItem("popupTimestamp");
  //   const now = new Date().getTime();

  //   // 24시간(1일) 지났는지 확인
  //   if (
  //     !popupTimestamp ||
  //     now - parseInt(popupTimestamp, 10) > 24 * 60 * 60 * 1000
  //   ) {
  //     setShowPopup(true); // 팝업 표시
  //   }
  // }, []);

  // // 1일 동안 보지 않기
  // const handlePopup = () => {
  //   const now = new Date().getTime();
  //   localStorage.setItem("popupTimestamp", now.toString());
  //   setShowPopup(false);
  // };

  return (
    <div className="relative flex flex-col">
{/*       {showPopup && (
        <Popup onClose={() => setShowPopup(false)} onDoNotShow={handlePopup} />
      )} */}

      <div>
        <PhotoSlider />
      </div>
      <div>
        <SideBar />
        <ScrollTopButton />
      </div>
      <div className="w-[80%] mx-auto mt-6">
        <Info />
      </div>
    </div>
  );
};

export default Home;
